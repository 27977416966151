import React from 'react';
import styled from '@emotion/styled';
import { D51MediaLogoHorizontal, UnsplashCredit, MechNinjaLogoHorizontal } from '@mechninja/mn-components';
import Helmet from 'react-helmet';
import HomeBackground from '../components/HomeBackground';

const LogoContainer = styled.div({
  position: 'absolute',
  top: '45%',
  left: '0',
  right: '0',
  color: 'white',
  fontFamily: 'Helvetica, sans-serif',
});

const Logo = styled.div({
  margin: '0 auto',
  textAlign: 'center',
  svg: {
    filter: 'drop-shadow(0 0 4mm rgb(0, 0, 0))',
  },
});

const index = () => (
  <React.Fragment>
    <Helmet>
      <title>D51Media</title>
    </Helmet>
    <HomeBackground />
    <UnsplashCredit userName="manchild" name="Wojciech Szaturski" align="bottom-left" />
    <LogoContainer>
      <Logo>
        <D51MediaLogoHorizontal color="white" width={400} />
        <p>
          part of
          {' '}
          <a href="https://www.mechanicalninja.com/">
            <MechNinjaLogoHorizontal color="white" height="1em" />
          </a>
        </p>
      </Logo>
    </LogoContainer>
  </React.Fragment>
);

export default index;
