import React from 'react';
import { Global } from '@emotion/core';

const HomeBackground = () => (
  <Global
    styles={{
      body: {
        maxWidth: '2000px',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center',
        background: 'url(/background.jpg)',
      },
    }}
  />
);

export default HomeBackground;
